<template>
    <div class="login">
        <div class="header">
            <div class="contain">
                <div class="logo-box">
                    <img
                        v-lazy="require('@/assets/home/logo.png')"
                        class="logo-img"
                    />
                    <div class="logo-content">
                        <div class="title">康美心理</div>
                        <div class="intro">康富美互联网医院</div>
                    </div>
                </div>
                <button class="login-btn" @click="back">返回首页</button>
            </div>
        </div>
        <div class="login-bg">
            <div class="login-box">
                <div class="login-title">欢迎来到，康美心理平台！</div>
                <div class="login-intro">请选择你的登录方式</div>
                <div class="login-methods">
                    <div
                        class="login-method"
                        v-for="(method, index) in loginMethods"
                        :key="index"
                    >
                        <div
                            class="method-img"
                            :style="
                                'background-image:url(' + method.imgUrl + ')'
                            "
                        ></div>
                        <div class="method-main reveal">
                            <img v-lazy="method.logo" class="logo" />
                            <button
                                class="btn btn-login"
                                @click="goto(method.url)"
                            >
                                登录{{ method.name }}
                            </button>
                            <button class="btn btn-login">注册{{ method.name }}</button>
                            <button
                                class="btn btn-register"
                                @click="goto(method.url)"
                            >
                                申请试用
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->
        
    </div>
</template>

<script>
    import scrollReveal from 'scrollreveal'
    export default {
        name: 'Login',
        data () {
            return {
                loginMethods: [
                    {
                        name: '医疗机构',
                        imgUrl: require('@/assets/login/login5.jpg'),
                        logo: require('@/assets/login/Group 64.png'),
                        // url: 'https://org.shangyiyunzhen.com/'
                        url: 'https://org.shangyiyunzhen.com/'
                    },
                    {
                        name: '心理咨询',
                        imgUrl: require('@/assets/login/login1.png'),
                        logo: require('@/assets/login/logo1.png'),
                        url: 'https://qs.shangyiyunzhen.com/'
                    }, 
                    {
                        name: '教育机构',
                        imgUrl: require('@/assets/login/login2.png'),
                        logo: require('@/assets/login/logo2.png'),
                        // url: 'https://p.mpt.cn/'
                        url: 'https://ys.shangyiyunzhen.com/'
                    },
                    {
                        name: '政企机构',
                        imgUrl: require('@/assets/login/login5.jpg'),
                        logo: require('@/assets/login/logo5.png'),
                        // url: 'https://p.mpt.cn/'
                        url: 'https://org.shangyiyunzhen.com/'
                    }
                ],
                scrollReveal: new scrollReveal()
            }
        },
        mounted () {
            this.scrollReveal.reveal('.reveal', {
                duration: 1000,
                delay: 150,
                reset: true,
                mobile: false,
                opacity: 0,
                easing: 'ease-in-out',
                enter: 'bottom',
                distance: '40px'
            });
        },
        methods: {
            back () {
                this.$router.replace('/')
            },
            goto (url) {
                window.location.href = url
            }
        }
    }
</script>
<style scoped lang="scss">
    $width: 1600px;
    .login {
        width: 100%;
        min-width: $width;
        height: 100%;
        background: $bg-color;
        .header {
            width: 100%;
            height: 80px;
            @include flex($justify: center);
            background: #fff;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            padding: 0 40px;

            .contain {
                width: $width;
                height: 100%;
                @include flex($justify: space-between);
                .logo-box {
                    @include flex;
                    .logo-img {
                        width: 54px;
                        height: 54px;
                    }
                    .logo-content {
                        margin-left: 10px;
                        .title {
                            font-size: 30px;
                            color: $title-color;
                            font-weight: bold;
                            line-height: 33px;
                        }
                        .intro {
                            font-size: 18px;
                            color: $intro-color;
                            line-height: 20px;
                            font-weight: bold;
                            margin-top: 4px;
                        }
                    }
                }

                .login-btn {
                    width: 100px;
                    line-height: 36px;
                    height: 36px;
                    border: 1px solid $color;
                    border-radius: 50px;
                    text-align: center;
                    color: $color;
                    background: #fff;
                    font-size: 16px;
                }

                .login-btn:hover {
                    background: $color;
                    color: #fff;
                }
            }
        }
        .login-bg {
            padding: 50px 0;
            @include flex($justify: center);
            background: $bg-color;
            .login-box {
                width: $width;
                height: 900px;
                background: url('~@/assets/login/login-bg.png');
                background-size: 1600px 900px;
                background-repeat: no-repeat;
                color: #fff;
                font-weight: 500;
                text-align: center;
                @include flex($direction: column, $justify: center);

                .login-title {
                    font-size: 60px;
                    margin-bottom: 20px;
                }

                .login-intro {
                    font-size: 24px;
                    margin-bottom: 90px;
                }

                .login-methods {
                    @include flex($justify: center);
                    .login-method {
                        position: relative;
                        width: 288px;
                        height: 448px;
                        overflow: hidden;
                        border-radius: 12px;
                        .method-img {
                            width: 288px;
                            height: 448px;
                            background-size: 288px 448px;
                            transition: all 0.15s ease-in-out;
                        }

                        .method-main {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 288px;
                            height: 448px;
                            overflow: hidden;
                            border-radius: 12px;
                            @include flex($direction: column, $justify: center);

                            .logo {
                                width: 100px;
                                height: 100px;
                                margin-bottom: 60px;
                            }

                            .btn {
                                width: 120px;
                                height: 40px;
                                line-height: 40px;
                                border-radius: 20px;
                                font-weight: 500;
                                text-align: center;
                                margin-bottom: 15px;
                            }

                            .btn.btn-register {
                                background: $color2;
                                color: $title-color;
                            }

                            .btn.btn-login {
                                background: $color;
                                color: #fff;
                            }
                        }
                    }
                    .login-method:hover .method-img {
                        transform: scale(1.2);
                    }

                    .login-method:not(:last-of-type) {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
</style>
